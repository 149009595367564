<template>
<b-modal
ref="edit_memo"
hide-footer no-enforce-focus
id="edit_memo"
title="Обработка служебной записки"
size="md"
@show="opened"
@shown="detectStartTime"
@hidden="detectEndTime"
>
    <div class="mt-1">
        <label>Номер клиента</label>
        <b-input disabled v-model="memo.phone" class="form-control" size="sm" type="text" v-mask="'############'" placeholder="Телефон"/>
    </div>
    <div class="mt-1">
        <label for="">Тема</label>
        <select disabled v-model="memo.memo_subject_id" class="form-control form-control-sm">
            <option v-for="subject in memo_subjects" :key="subject.id" :value="subject.id">{{subject.name}}</option>
        </select>
    </div>
    <div class="mt-1">
        <label for="">Тип</label>
        <select disabled v-model="memo.memo_type_id" class="form-control form-control-sm">
            <option v-for="memo_type in memo_types" :key="memo_type.id" :value="memo_type.id">{{memo_type.name}}</option>
        </select>
    </div>
    
    <div class="mt-1">
        <label for="">Комментарий</label>
        <b-textarea disabled v-model="memo.comment"></b-textarea>
    </div>
    <hr>
    <div class="mt-1">
        <label for="">Причина(Не обработки)</label>
        <select v-model="memo.cause_id" class="form-control form-control-sm">
            <option v-for="cause in memos_causes" :key="cause.id" :value="cause.id">{{cause.name}}</option>
        </select>
    </div>
    <div class="d-flex justify-content-end">
        <b-button
        :disabled="submitButtonDisabled"
        class="col-md-3 mt-1 mr-1 align-self-end"
        variant="primary"
        size="sm"
        block
        @click="editMemo"
    >
    Ок
    </b-button>
    <b-button
        class="col-md-3 mt-1 align-self-end"
        variant="secondary"
        size="sm"
        block
        @click="closeModal()"
    >
    Отмена
    </b-button>
    </div>
</b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mask } from "vue-the-mask"

export default {
    directives: { mask },
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent,
    },
    data(){
        return {
            formTime: {
                form_name: null,
                start_time: null,
                end_time: null,
            },
            memo: {},
            memo_subjects: [],
            memo_types: [],
            divisions: [],
            memos_causes:[],
            memos_statuses: [],
            comment:'',
            submitButtonDisabled: false,
        }
    },
    mounted(){
    },
    methods: {
        detectEndTime() {
            this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.$http.post('time-form', this.formTime)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            }) 
        },
        detectStartTime() {
            this.formTime.form_name = 'memosEditForm'
            this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        },
        opened(){
            this.$http
                .get(`memos/${this.id}/edit`)
                .then(res => {
                    this.memo = res.data
                    this.comment=this.memo.comment
                    if (this.memo.user.first_name) {
                        this.memo.comment=`История: ${this.memo.user.first_name+' '+this.memo.user.last_name+'('+this.memo.created_at+')'}\n${this.comment}`  
                    }
            })
            this.$http
            .get('memos/lists')
            .then(res => { 
                this.memo_subjects=res.data.memo_subject
                this.memo_types=res.data.memo_type
            })
            this.$http
            .get('orders/data')
            .then(res => { 
                this.divisions = res.data.divisions
            })
            this.$http
            .get('memos/data')
            .then(res => { 
                this.memos_statuses = res.data.statuses
                this.memos_causes = res.data.causes
            })
        },
        closeModal(){
            this.$bvModal.hide('edit_memo')
        },
        editMemo(){
            this.memo.comment=this.comment
            this.memo.cause_id=undefined
            this.submitButtonDisabled = true
            this.$http
                .patch(`memos/${this.id}`, this.memo)
                .then(res => {
                    this.$bvModal.hide('edit_memo')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Обработан!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        }
    }

}
</script>