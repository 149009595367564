<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="memos_card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="memos"
                    :fields="fields"
                    @row-dblclicked="editForm"
                    @row-clicked="clicked"
                    head-row-variant="secondary"
                    @row-contextmenu="handleContextMenuEvent"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(user)="data">
                        {{ data.item.user ? data.item.user.first_name+' '+ data.item.user.last_name+`(${data.item.user.role})` : ''}}
                    </template>
                    <template #cell(update_user)="data">
                        {{data.item.update_user==null? '' :data.item.update_user.first_name+' '+ data.item.update_user.last_name+`(${data.item.update_user.role})`}}
                    </template>
                </b-table>
                    <vue-context ref="menu">
                            <li>
                                <b-link
                                class="d-flex align-items-center"
                                @click="getOrderById()"
                                v-b-modal.edit_memo
                                >
                                <feather-icon
                                    icon="EditIcon"
                                    size="16"
                                />
                                <span class="ml-75">Обработать</span>
                                </b-link>
                            </li>
                        </vue-context>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/css/bootstrap.css'
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    components: {
        VueContext
    },
    props: ['memos'],
    data() {
        return {
            openCar: false,
            fields: [
                {key: 'checkbox', label: '', thStyle: {width: '30px'}},
                {key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                {key: 'user', label: 'Создал', sortable: true, thStyle: {width: '150px'}},
                { key: 'created_at', label: 'Время поступления', sortable: true, thStyle: {width: '150px'} },
                {key: 'memo_subject.name', label: 'Тема служебной записки', sortable: true, thStyle: {width: '150px'}},
                {key: 'memo_type.name', label: 'Тип служебной записки', sortable: true, thStyle: {width: '150px'}},
                { key: 'comment', label: 'Комментарий', sortable: true, thStyle: {width: '150px'} },
                { key: 'phone', label: 'Телефон', sortable: true, thStyle: {width: '150px'} },
                { key: 'status.name', label: 'Статус', sortable: true, thStyle: {width: '150px'} },
                { key: 'cause.name', label: 'Причина', sortable: true, thStyle: {width: '150px'} },
                { key: 'update_user', label: 'Обработал', sortable: true, thStyle: {width: '150px'} },
                { key: 'updated_at', label: 'Время обработки', sortable: true, thStyle: {width: '150px'} },


            ],
            id: '',
            memosId: ''
        }
    },
    mounted(){
        resizeable()
    },
    methods: {
        editForm(id){
            this.$emit('editForm', id.id)
        },
        handleContextMenuEvent(item, index, event){
           event.preventDefault();
           this.$refs.menu.open(event)
           this.id = item.id
        }, 
        getOrderById(){
            this.$emit('getOrderById', this.id)
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.memos_card .card-body{
    padding: 0px;
}
.memos_card{
overflow: auto; 
height: calc(100vh - 92px);
@font-face {
        font-family: "Verdana";
        src: url("../../../assets/fonts/Verdana.ttf");
    }
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
}
@media (max-height: 700px) {
  .memos_card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }    
</style> 