<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-memos :memos="memos" @editForm="editForm" @refresh="refresh" @getOrderById="getOrderById"/>
            <!-- <modal-car @refresh="refresh" /> -->
            <!-- <modal-car-filter @filteredCars="filteredCars"></modal-car-filter> -->
        </div>
        <modal-memos-edit :id="id" @refresh="refresh" />
        <modal-order-complaint></modal-order-complaint>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableMemos from "@/views/component/Table/tableMemos.vue"
// import modalCar from "@/views/component/Modal/ModalCar/modalCar.vue"
import modalMemosEdit from "@/views/component/Modal/ModalMemos/modalMemosEdit.vue"
import modalOrderComplaint from "@/views/component/Modal/ModalOrder/ModalOrderСomplaint.vue"
// import modalCarFilter from "@/views/component/Modal/ModalCar/modalCarFilter.vue"


export default {
    components: {
        tableMemos,
        modalMemosEdit,
        modalOrderComplaint
    },
    data(){
        return {
            id: this.$route.query.order_id,
             fields: [
                {key: 'checkbox', label: '', thStyle: {width: '30px'}},
                {key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                // {key: 'memo_subject.name', label: 'Тема служебной записки', sortable: true, thStyle: {width: '150px'}},
                // {key: 'memo_type.name', label: 'Тип служебной записки', sortable: true, thStyle: {width: '150px'}},
                {key: 'comment', label: 'Комментарий', sortable: true, thStyle: {width: '150px'} },
                {key: 'date_time', label: 'На время', sortable: true, thStyle: {width: '150px'} },
                {key: 'phone', label: 'Телефон', sortable: true, thStyle: {width: '150px'} },
        ],
            id: '',
            memos: [],
            showPreloader: false,
            performerPhone: null,
            showPreloader: false
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
        this.refresh()
    },
    methods: {
    //     filteredCars(cars){
    //        this.cars=cars
    //    },
    openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if(this.perfomerPhone) {
                param.filter_phone = this.perfomerPhone;
            }
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.memos = []
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.memos = res.data
                        this.$store.commit('pageData/setdataCount', this.memos.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
            this.$store.state.filterPlus.filterValue = null
        },
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        getOrderById(id) {
            this.id = id
        },
        sendToParent(tableData){
            this.memos = tableData
            this.$store.commit('pageData/setdataCount', this.memos.length)
        },
        openModal(){
            this.$bvModal.show('edit_memo')
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('memos').then(res => {
                    this.memos = res.data
                    this.$store.commit('pageData/setdataCount', this.memos.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        }
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        '$store.state.filterPlus.filterValue': {
            immediate: true,
            handler(newFilterValue) {
                if (newFilterValue) {
                    this.perfomerPhone = '992' + newFilterValue
                    this.openFilter(); 
                }
            },
        }, 
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.memos = res.data
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>